import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

import { fadeIn, zoomIn } from "../../Functions/GlobalAnimations";

import Testimonials from "./Testimonials";

import { TestimonialsData04 } from "./TestimonialsData";

/** image */
import TestimonialBottom from "../../../Assets/img/DrDKMishra/testimonials/testimonial-bottom.webp";

const TestimonialsSection = () => {
  return (
    <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
      <Container>
        <Row className="justify-center">
          <Col
            xl={6}
            lg={7}
            md={8}
            sm={10}
            className="text-center mb-10 sm:mb-8"
          >
            <span className="font-serif font-medium text-[#00b8b8] uppercase block mb-[20px] tracking-[1px]">
              OUR PATIENTS REVIEW
            </span>
            <h2 className="heading-4 font-serif font-semibold text-darkgray tracking-[-1px]">
              All the reviews stated below are real and has been taken from our
              google reviews.
            </h2>
          </Col>
        </Row>
        <Testimonials
          grid="row-cols-1 row-cols-md-2 row-cols-lg-3 justify-center mb-36 md:gap-y-[30px] sm:mb-20 xs:mb-10"
          theme="testimonials-style-04"
          className="col-sm-8"
          data={TestimonialsData04}
          animation={fadeIn}
        />
        <Row className="justify-center">
          <Col md={8} className="text-center">
            <m.img
              width={713}
              height={75}
              className="mx-auto"
              src={TestimonialBottom}
              alt=""
              {...{ ...zoomIn, transition: { duration: 0.7 } }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TestimonialsSection;
