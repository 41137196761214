const imageGalleryData01 = [
  {
    src: "https://via.placeholder.com/800x989",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x989",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x989",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x989",
    title: "Lightbox gallery image title",
  },
];

const imageGalleryData02 = [
  {
    double_col: true,
    src: "https://via.placeholder.com/800x794",
    title: "Lightbox gallery image title",
  },
  {
    double_col: true,
    src: "https://via.placeholder.com/800x387",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x794",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x794",
    title: "Lightbox gallery image title",
  },
];

const imageGalleryData03 = [
  {
    src: "https://via.placeholder.com/800x800",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x1200",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x800",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x800",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x1200",
    title: "Lightbox gallery image title",
  },

  {
    src: "https://via.placeholder.com/800x1200",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x1200",
    title: "Lightbox gallery image title",
  },
  {
    src: "https://via.placeholder.com/800x800",
    title: "Lightbox gallery image title",
  },
];

export { imageGalleryData01, imageGalleryData02, imageGalleryData03 };
