import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./DrDkMishra/Context/Context";

// Components
import ScrollToTopButton from "./DrDkMishra/Components/ScrollToTop";

import HeaderAlpha from "./DrDkMishra/Components/HeaderMain";
import FooterAlpha from "./DrDkMishra/Components/FooterMain";
import NotFoundPage from "./DrDkMishra/Pages/404";
import {
  UreteroRenoscopy,
  Turp,
  Turbt,
  Pcml,
  Oiu,
  Lithotrispy,
  LaserSurgery,
  LaparoscopicUrologicalSurgery,
  LaparoscopicGallStoneSurgery,
  Cystoscopy,
  AllOpenUrologicalSurgery,
  UroOncology,
  ImpotenceClinic,
} from "./DrDkMishra/Pages/Services";
import Loader from "./DrDkMishra/Components/Loader/Loader";

const Header = React.lazy(() =>
  import("./DrDkMishra/Components/HeaderMain/Header").then((module) => ({
    default: module.Header,
  }))
);

// pages

const HomePage = lazy(() => import("./DrDkMishra/Pages/Home"));
const AboutUs = lazy(() => import("./DrDkMishra/Pages/AboutUs"));
const Projects = lazy(() => import("./DrDkMishra/Pages/Projects"));
const Contact = lazy(() => import("./DrDkMishra/Pages/ContactUs"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Theme/Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            {/* Header Start */}
            <Header topSpace={{ md: false }} type="reverse-scroll">
              <HeaderAlpha />
            </Header>

            {/* Header End */}
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage style={{ "--base-color": "#27ae60" }} />}
                  />
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="projects" element={<Projects />} />
                  <Route path="services">
                    <Route
                      path="ureteroRenoscopy"
                      element={<UreteroRenoscopy />}
                    />
                    <Route path="turp" element={<Turp />} />
                    <Route path="uroOncology" element={<UroOncology />} />
                    <Route path="turbt" element={<Turbt />} />
                    <Route path="pcml" element={<Pcml />} />
                    <Route path="oiu" element={<Oiu />} />
                    <Route path="lithotrispy" element={<Lithotrispy />} />
                    <Route path="laserSurgery" element={<LaserSurgery />} />
                    <Route
                      path="laparoscopicUrologicalSurgery"
                      element={<LaparoscopicUrologicalSurgery />}
                    />
                    <Route
                      path="laparoscopicGallStoneSurgery"
                      element={<LaparoscopicGallStoneSurgery />}
                    />
                    <Route path="cystoscopy" element={<Cystoscopy />} />
                    <Route
                      path="allOpenUrologicalSurgery"
                      element={<AllOpenUrologicalSurgery />}
                    />
                    <Route
                      path="impotenceClinic"
                      element={<ImpotenceClinic />}
                    />
                  </Route>

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
            {/* Footer Start */}

            <FooterAlpha />
            {/* Footer End */}
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
