import React from "react";
import { Col, Container, Navbar } from "react-bootstrap";

import Header, { HeaderNav, Menu } from "./Header";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import { Link } from "react-router-dom";
import Logo from "../../../Assets/img/DrDKMishra/icons/logo.webp";

const SocialIconsData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/dkmishraurologists/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#828282",
    link: "https://instagram.com/dr_dk_mishra_urologist?igshid=MzRlODBiNWFlZA==",
    icon: "fab fa-instagram",
  },
  // {
  //   color: "#828282",
  //   link: "https://twitter.com/",
  //   icon: "fab fa-twitter",
  // },
];

const HeaderMain = (props) => {
  return (
    <>
      <div className="bg-[#1D2C5A] border-b border-[#0000001a]  md:px-[15px] h-[50px] lg:h-[100px] md:h-[70px]  sm:h-[60px] overflow-hidden">
        <Container
          fluid="fluid"
          className="py-[0px] px-[35px] md:pr-[0px] md:pl-0 md:py-[20px] border-b border-[#0000001a] flex  items-center justify-between md:justify-start h-full w-full z-50"
        >
          <div className="row flex justify-between items-center w-full">
            <Col className="col-auto text-center items-center flex text-sm-start me-auto">
              <span className="text-[#fff] text-[13px]  sm:text-[8px] md:text-[10px] font-normal    whitespace-nowrap">
                Emergency visit consultation fees (between 9 PM Night to 6 AM
                Morning): Rs 5000/-
              </span>
            </Col>
            <Col className="col-auto flex  text-right h-full justify-between items-center">
              <div className="top-bar-contact flex justify-center items-center gap-3">
                <span className=" flex pl-0     text-[13px] md:text-[10px] !leading-relaxed sm:text-[8px] font-normal text-[#fff]">
                  Consultation fees for the first visit: Rs 1000/-
                </span>
                <div className="h-[20px] bg-gray-500 w-[2px] ml-2 "></div>
                <span className="  text-[13px] md:text-[10px]  sm:text-[8px] font-normal text-[#fff] !leading-relaxed ">
                  Follow-up fees: Rs 800/-
                </span>
              </div>
            </Col>
          </div>
        </Container>
      </div>
      {/* Header Start */}
      <Header
        topSpace={{ md: true }}
        type="reverse-scroll"
        className="mt-[50px]  lg:mt-[100px] sm:mt-[60px] md:mt-[70px]"
      >
        <HeaderNav
          theme="light"
          expand="lg"
          containerClass="sm:!px-0"
          className="py-[0px] px-[35px] md:pr-[15px] md:pl-0 md:py-[20px] border-b border-[#0000001a] "
          fluid="fluid"
        >
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={Logo}
                  data-rjs={Logo}
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={Logo}
                  data-rjs={Logo}
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={Logo}
                  data-rjs={Logo}
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-center col-auto col-lg-8 menu-order px-lg-0">
            <Menu {...props} />
          </Navbar.Collapse>
          <Col className="col-auto col-lg-2 pe-0">
            <SocialIcons
              theme="social-icon-style-01"
              size="xs"
              iconColor="dark"
              className="justify-end"
              data={SocialIconsData}
            />
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
};

export default HeaderMain;
