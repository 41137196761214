import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Col, Container, Row } from "react-bootstrap";

import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import Overlap from "../../Components/Overlap/Overlap";
import TestimonialsSection from "../../Components/Testimonials/TestimonialsSection";

/** images */
import Banner from "../../../Assets/img/DrDKMishra/services/turbt/banner.webp";
import FirstCard from "../../../Assets/img/DrDKMishra/services/turbt/card-1.webp";
import SecondCard from "../../../Assets/img/DrDKMishra/services/turbt/card-2.webp";
import ThirdCard from "../../../Assets/img/DrDKMishra/services/turbt/card-3.webp";

const ImagesGalleryData = [
  {
    src: FirstCard,
    title: "",
    double_col: true,
  },
  {
    src: SecondCard,
    title: "",
  },
  {
    src: ThirdCard,
    title: "",
  },
];

const Turbt = () => {
  return (
    <div>
      {/* Parallax Scrolling Start */}
      <div className="h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 w-full h-[700px] sm:h-[400px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(${Banner})`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full top-0 left-0 bg-gradient-to-tr from-[#c3f4e9] via-[#c9f5eb]  to-[#fbe3c2] opacity-75"></div>
        <Container>
          <Row className="items-center justify-center">
            <Col md={8} xl={8} lg={8} sm={9} className="relative text-center">
              {/* <h1 className="inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif">
                About our company
              </h1> */}
              <h2 className="font-serif text-[#232323] -tracking-[1px] text-[3.9rem] font-medium mb-0 sm:-tracking-[1px]">
                TURBT
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section className="pt-[130px] pb-[400px] overflow-hidden lg:pt-[90px] lg:pb-[320px] md:pt-[75px] md:pb-[310px] sm:py-[50px]">
        <Container>
          <Row>
            <Col
              lg={6}
              sm={12}
              className="text-start md:mb-[20px] sm:text-center"
            >
              <div className="flex font-serif mb-[15px]">
                <span className="align-self-center w-[30px] h-[1px] bg-[#00b8b8] mr-[10px] md:inline-block sm:hidden"></span>
                <div className="flex-grow-1 text-[#00b8b8] font-medium sm:text-center">
                  TURBT
                </div>
              </div>
              <h4 className="font-serif font-medium text-darkgray mb-0 w-[90%] md:w-full sm:text-center xs:w-full">
                Empowering Urology through TURBT
              </h4>
            </Col>
            <Col lg={6} className="text-start">
              <p className="w-[95%] lg:w-full sm:text-center">
                Our innovative TURBT (Transurethral Resection of Bladder Tumor)
                technique revolutionizes urological care, offering precision and
                relief to patients. Using advanced endoscopic tools, our skilled
                urologists delicately remove bladder tumors, minimizing
                invasiveness and maximizing patient comfort. Trust our expertise
                in TURBT to provide comprehensive bladder tumor management with
                a patient-centric approach.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start*/}
      <section className="bg-lightgray pb-[130px] lg:pb-[90px] md:pb-[75px] sm:py-[50px]">
        <Container>
          <Row>
            <Overlap value={22} className="px-0">
              <ImageGallery
                grid="grid grid-2col xl-grid-2col lg-grid-2col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                theme="image-gallery-02"
                data={ImagesGalleryData}
                overlay="#232323"
              />
            </Overlap>
          </Row>
        </Container>
      </section>
      {/* Section Start*/}

      {/* Section Start */}
      <TestimonialsSection />
      {/* Section End */}
    </div>
  );
};

export default Turbt;
