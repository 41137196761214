const HeaderDataAlpha = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Services",

    megamenu: [
      {
        title: "",
        dropdown: [
          {
            title: "OIU",
            link: "/services/oiu",
          },
          {
            title: "TURP",
            link: "/services/turp",
          },
          {
            title: "TURBT",
            link: "/services/turbt",
          },
          {
            title: "P.C.M.L ",
            link: "/services/pcml",
          },

          {
            title: "CYSTOSCOPY",
            link: "/services/cystoscopy",
          },
          {
            title: "LASER SURGERY ",
            link: "/services/laserSurgery",
          },

          {
            title: "LITHOTRISPY (ESWL)",
            link: "/services/lithotrispy",
          },
        ],
      },
      {
        title: "",
        dropdown: [
          {
            title: "URO ONCOLOGY",
            link: "/services/uroOncology",
          },
          {
            title: "IMPOTENCE CLINIC",
            link: "/services/impotenceClinic",
          },
          {
            title: "URETERO - RENOSCOPY (URS)",
            link: "/services/ureteroRenoscopy",
          },
          {
            title: "ALL OPEN UROLOGICAL SURGERY",
            link: "/services/allOpenUrologicalSurgery",
          },
          {
            title: "LAPAROSCOPIC GALL STONE SURGERY",
            link: "/services/laparoscopicGallStoneSurgery",
          },
          {
            title: "LAPAROSCOPIC UROLOGICAL SURGERY",
            link: "/services/laparoscopicUrologicalSurgery",
          },
        ],
      },
    ],
  },
  // {
  //   title: "Projects",
  //   link: "/projects",
  // },
  {
    title: "Contact Us",
    link: "/contact",
  },
];

export default HeaderDataAlpha;
