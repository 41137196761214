import img1 from "../../../Assets/img/DrDKMishra/testimonials/1.png";
import img2 from "../../../Assets/img/DrDKMishra/testimonials/2.png";
import img3 from "../../../Assets/img/DrDKMishra/testimonials/3.png";
import img4 from "../../../Assets/img/DrDKMishra/testimonials/4.png";
import img5 from "../../../Assets/img/DrDKMishra/testimonials/5.png";

const TestimonialsData01 = [
  {
    name: "SHOKO MUGIKURA",
    designation: "GRAPHIC DESIGNER",
    content:
      "Their team are easy to work with and helped me make amazing websites in a short amount of time. Thanks guys for all your hard work.",
    img: "https://via.placeholder.com/125x125",
  },
  {
    name: "JONSAN DONNER",
    designation: "SALES MANAGER",
    content:
      "Trust us we looked for a very long time and wasted thousands of dollars testing other teams, freelancers, and outsource companies.",
    img: "https://via.placeholder.com/125x125",
  },
  {
    name: "MACKANGY ROSE",
    designation: "CREATIVE DIRECTOR",
    content:
      "This is an excellent company! I personally enjoyed the energy and the professional support the whole team gave to us into creating website.",
    img: "https://via.placeholder.com/125x125",
  },
];

const TestimonialsData02 = [
  {
    name: "JEREMY SMITH",
    designation: "CO FOUNDER",
    content:
      "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt labore ut enim ad minim veniam",
    img: "https://via.placeholder.com/800x580",
  },
  {
    name: "SHOKO MUGIKURA",
    designation: "CREATIVE HEAD",
    content:
      "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt labore ut enim ad minim veniam",
    img: "https://via.placeholder.com/800x580",
  },
  {
    name: "HERMAN MILLER",
    designation: "HR MANAGER",
    content:
      "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt labore ut enim ad minim veniam",
    img: "https://via.placeholder.com/800x580",
  },
];

const TestimonialsData03 = [
  {
    name: "Herman Miller",
    designation: "ThemeZaa Design",
    content:
      "Lorem ipsum dolor amet consectetur do tempor incididunt labore et dolore magna nostrud exercitation ullamco.",
    img: "https://via.placeholder.com/125x125",
  },
  {
    name: "Jeremy Girard",
    designation: "Microsoft Corporation",
    content:
      "Lorem ipsum dolor amet consectetur do tempor incididunt labore et dolore magna nostrud exercitation ullamco.",
    img: "https://via.placeholder.com/125x125",
  },
  {
    name: "Alexander Harvard",
    designation: "ThemeZaa Design",
    content:
      "Lorem ipsum dolor amet consectetur do tempor incididunt labore et dolore magna nostrud exercitation ullamco.",
    img: "https://via.placeholder.com/125x125",
  },
];

const TestimonialsData04 = [
  {
    name: "Rahul Kumar Bhagat",
    content:
      "He is the best doctor for stone problem in jsr. His fees and operation charge is less as compared to other doctor. He has his own hospital in baradwari where he do operation and at sakchi near sitla mandir he has clinic.",
    img: img1,
    rating: 5,
  },
  {
    name: "Shrey Tripathy",
    content:
      "It has been an outstanding experience to receive treatment under Dr. DK.Mishra sir and Dr. Patanjali Mishra sir. Best and the nicest urologist in Jamshedpur and specifically clears all the doubts of the patient and suggests the best choice of treatment available. Thank you sir for such an amazing treatment.",
    img: img2,
    rating: 5,
  },
  {
    name: "Ankit Mandal",
    content:
      "Dr. D.K. Mishra is probably the best doctor I have ever had. He is also one of the nicest people I know. I am delighted he is my Urologist!",
    img: img3,
    rating: 5,
  },
  {
    name: "Ankit Kumar",
    content:
      "Dr. D.K. Mishra and his hospital staff are very caring and I am extremely pleased with 5 star review to Dr. D.k Mishra Nephro Urology and Stone Center sakchi, jamshedpur, jharkhand. Specialist in Urology and Nephrology.",
    img: img4,
    rating: 5,
  },
  {
    name: "JRL Arogyamm",
    content:
      "I’ve been a patient for over 25 years. I’ve had kidney stones and two laser procedures on my prostate. The staff are outstanding – I can’t say enough about them. I would recommend Urology Associates to anyone with urological problems.",
    img: img5,
    rating: 5,
  },
];

const TestimonialsData05 = [
  {
    name: "Herman Miller",
    content:
      "Simply superb resort. Management and staff deserve special appreciation and thanks for hospitality.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
  {
    name: "Loretta Smith",
    content:
      "We are back home now and feel that it is only right to write to you to express our warmest gratitude.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
  {
    name: "Jeremy Girard",
    content:
      "Wonderful place to live for holidays. Can’t believe can have such beautiful environment to spend peaceful.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
];

export {
  TestimonialsData01,
  TestimonialsData02,
  TestimonialsData03,
  TestimonialsData04,
  TestimonialsData05,
};
